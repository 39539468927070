import { ChoppbotEvent } from "@/helpers/interfaces/events";
import {
  ReceiptLong,
  EditOutlined,
  SportsBarOutlined,
  ReplayOutlined,
  DeleteOutline,
  QrCode2,
  ReceiptLongOutlined,
  Sync,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import {
  GridColDef,
  GridRowParams,
  GridActionsCellItem,
} from "@mui/x-data-grid";

export const eventsColumns = ({
  onEditBeers,
  onEditEvent,
  onSyncEvent,
  goToReceipts,
  onShowQrCode,
  onDeleteEvent,
  onResetReceipts,
  onDeleteCustomers,
}: {
  onEditEvent: (event: ChoppbotEvent) => void;
  onEditBeers: (event: ChoppbotEvent) => void;
  onSyncEvent: (event: ChoppbotEvent) => void;
  onShowQrCode: (event: ChoppbotEvent) => void;
  goToReceipts: (event: ChoppbotEvent) => void;
  onDeleteEvent: (event: ChoppbotEvent) => void;
  onResetReceipts: (event: ChoppbotEvent) => void;
  onDeleteCustomers: (event: ChoppbotEvent) => void;
}) => {
  const columns: GridColDef<ChoppbotEvent>[] = [
    {
      field: "started_at",
      headerName: "Início",
      minWidth: 200,
      sortable: false,
      filterable: false,
      valueGetter: (params) => {
        if (params.value) {
          return params.value;
        }

        return "N/D";
      },
    },
    {
      field: "name",
      headerName: "Evento",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "category",
      headerName: "Categoria",
      valueFormatter(params) {
        switch (params.value as ChoppbotEvent["category"]) {
          case "open":
            return "Open bar";
          case "prepaid":
            return "Pré-pago";
          case "postpaid":
            return "Pós-pago";
          default:
            return "---";
        }
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      valueFormatter(params) {
        switch (params.value as ChoppbotEvent["status"]) {
          case "created":
            return "Criado";
          case "activated":
            return "Em andamento";
          default:
            return "Finalizado";
        }
      },
    },
    {
      field: "link",
      headerName: "QR Code",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<QrCode2 />}
          label="QR Code"
          onClick={() => onShowQrCode(params.row)}
        />,
      ],
    },
    {
      field: "receipts",
      headerName: "Comandas",
      align: "center",
      filterable: false,
      sortable: false,
      width: 80,
      renderCell(params) {
        return (
          <IconButton onClick={() => goToReceipts(params.row)}>
            <ReceiptLong />
          </IconButton>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Ações",
      headerAlign: "center",
      filterable: false,
      sortable: false,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          icon={<EditOutlined />}
          onClick={() => onEditEvent(params.row)}
          label="Editar evento"
          showInMenu
        />,
        <GridActionsCellItem
          icon={<SportsBarOutlined />}
          onClick={() => onEditBeers(params.row)}
          label="Criar bebidas"
          showInMenu
        />,
        <GridActionsCellItem
          onClick={() => onSyncEvent(params.row)}
          label="Sincronizar"
          icon={<Sync />}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<ReceiptLongOutlined color="error" />}
          onClick={() => onResetReceipts(params.row)}
          label="Resetar comandas"
          showInMenu
        />,
        <GridActionsCellItem
          icon={<ReplayOutlined color="error" />}
          onClick={() => onDeleteCustomers(params.row)}
          label="Resetar evento"
          showInMenu
        />,
        <GridActionsCellItem
          icon={<DeleteOutline color="error" />}
          onClick={() => onDeleteEvent(params.row)}
          label="Remover evento"
          showInMenu
        />,
      ],
    },
  ];
  return columns;
};
