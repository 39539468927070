import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  Switch,
  SxProps,
  TextField,
  Theme,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import {
  backendFormFieldFromEnum,
  formFieldsModel,
} from "@/helpers/availableFormFields";
import {
  ChoppbotEvent,
  ChoppbotEventFormField,
} from "@/helpers/interfaces/events";
import { createEvent, putEvent } from "@/helpers/api/events";

interface AddEventModalProps {
  title: string;
  open: boolean;
  eventId: number | null;
  onSubmit: (sucess: boolean) => void;
  eventName: string;
  close: () => void;
  eventType: string;
  descriptionName: string | null;
  prevFormFields: ChoppbotEvent["form_fields"];
  typeSend: "POST" | "PUT";
}

function initializeFormFields(prevFormFields: ChoppbotEvent["form_fields"]) {
  const newFormFields: any = {};
  if (!!prevFormFields) {
    Object.entries(formFieldsModel).forEach(([key, field]) => {
      newFormFields[key] = {
        required: field.required,
        checked:
          prevFormFields[
            backendFormFieldFromEnum(key as ChoppbotEventFormField)
          ],
      };
    });
    return newFormFields as typeof formFieldsModel;
  }
  return formFieldsModel;
}

export default function AddEventModal({
  eventName,
  prevFormFields,
  descriptionName,
  eventType,
  open,
  eventId,
  title,
  close,
  onSubmit,
  typeSend,
}: AddEventModalProps) {
  const [nameEvent, setNameEvent] = useState(eventName);
  const [descriptionEvent, setDescriptionEvent] = useState(descriptionName);
  const [typeEvent, setTypeEvent] = useState(eventType);
  const [formFields, setFormFields] = useState(
    initializeFormFields(prevFormFields)
  );

  const createEventForm = async () => {
    const formData = new FormData();
    const formFieldsData: any = {};
    Object.entries(formFields).forEach(([key, field]) => {
      formFieldsData[backendFormFieldFromEnum(key as ChoppbotEventFormField)] =
        field.checked;
    });

    formData.append("franchisee_id", "1");
    formData.append("name", nameEvent);
    if (descriptionEvent) formData.append("description", descriptionEvent);
    formData.append("category", typeEvent === "" ? "postpaid" : typeEvent);
    formData.append("limit_beer", "5");
    formData.append("form_fields", JSON.stringify(formFieldsData));

    if (typeSend === "POST") {
      await createEvent(formData)
        .then(() => {
          onSubmit(true);
        })
        .catch(() => onSubmit(false));
    }
    if (typeSend === "PUT") {
      await putEvent(eventId!, formData)
        .then(() => {
          onSubmit(true);
        })
        .catch(() => onSubmit(false));
    }
  };

  function handleFormFieldChange(
    key: ChoppbotEventFormField,
    checked: boolean
  ) {
    setFormFields((prev) => ({
      ...prev,
      [key]: {
        required: false,
        checked,
      },
    }));
  }

  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          size="small"
          margin="dense"
          label="Nome do evento"
          value={nameEvent}
          onChange={(ev) => setNameEvent(ev.target.value)}
        />
        <TextField
          fullWidth
          multiline
          margin="dense"
          minRows={3}
          label="Descrição"
          value={descriptionEvent}
          onChange={(ev) => setDescriptionEvent(ev.target.value)}
        />
        <TextField
          select
          fullWidth
          size="small"
          margin="dense"
          label="Tipo de evento"
          value={typeEvent || "open"}
          onChange={(ev) => setTypeEvent(ev.target.value)}
        >
          <MenuItem value="open">Open</MenuItem>
          <MenuItem value="postpaid" disabled>Pós-pago</MenuItem>
          <MenuItem value="prepaid" disabled>
            Pré-pago
          </MenuItem>
        </TextField>
        <h3 style={{ marginBlock: "8px" }}>Campos do formulário</h3>
        <EventFormFields
          formFields={formFields}
          handleFormFieldChange={handleFormFieldChange}
        />
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={close}>
          Cancelar
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            createEventForm();
          }}
        >
          {typeSend === "PUT" ? "Salvar" : "Criar evento"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function EventFormFields({
  sx,
  formFields,
  handleFormFieldChange,
}: {
  sx?: SxProps<Theme>;
  formFields: Record<
    ChoppbotEventFormField,
    { required: boolean; checked: boolean }
  >;
  handleFormFieldChange: (
    key: ChoppbotEventFormField,
    checked: boolean
  ) => void;
}) {
  return (
    <Grid2 container spacing={2} sx={sx}>
      {Object.entries(formFields).map(([key, field]) => (
        <Grid2 key={key} xs={12} sm={4}>
          <FormControlLabel
            checked={field.checked}
            disabled={field.required}
            control={
              <Switch
                onChange={(ev) =>
                  handleFormFieldChange(
                    key as ChoppbotEventFormField,
                    ev.target.checked
                  )
                }
              />
            }
            label={key}
          />
        </Grid2>
      ))}
    </Grid2>
  );
}
