import React, { useContext, useEffect } from "react";
import Sidebar from "./components/Sidebar/Sidebar";
import { Outlet, useNavigate } from "react-router-dom";
import LoginAuth from "./helpers/context/LoginContext";
import Header from "./components/Header/Header";

export default function MainLayout() {
  const { user } = useContext(LoginAuth);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/auth/login");
    }
  }, [user, navigate]);

  return (
    <>
      <Header />
      <div className="appContainer primary-container">
        <Sidebar />
        <Outlet />
      </div>
    </>
  );
}
