import React, { useEffect } from "react";
import LoginAuth from "./LoginContext";
import useStorage from "@/helpers/hooks/useStorage";
import rootApi from "../api/rootConfig";

const AuthProvider = ({ children }: React.PropsWithChildren) => {
  const [user, setUser] = useStorage("user");
  const [baseURL, setBaseURL] = useStorage("baseURL");
  // Updating root api headers
  rootApi.defaults.headers.Authorization = "Bearer " + user?.token;

  return (
    <LoginAuth.Provider
      value={{
        user,
        setUser,
        baseURL,
        setBaseURL,
      }}
    >
      {children}
    </LoginAuth.Provider>
  );
};

export default AuthProvider;
