import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import AuthProvider from "./helpers/context/Provider";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { choppbotRoutes } from "./routes";

// Styles: The order of imports matter here
import "./styles/rootStyles.css";
import "./styles/md3/theme.css";
import { ThemeProvider } from "@mui/material";
import { theme } from "./styles/muiRoot";

const router = createBrowserRouter(choppbotRoutes);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </AuthProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
