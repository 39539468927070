import React, { useState } from "react";
import { Button, MenuItem, TextField } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import styles from "./newFilter.module.css";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

interface OccurrenceFilterProps {
  filterData: {
    name: string;
    cpf: string;
    phone: string;
    status: string;
  };
  setFilterData: React.Dispatch<
    React.SetStateAction<{
      name: string;
      cpf: string;
      phone: string;
      status: string;
    }>
  >;
  filter: (filterData: {
    name: string;
    cpf: string;
    phone: string;
    status: string;
  }) => any;
  clearFilters: () => any;
}

function getInitialFilterData() {
  return {
    name: "",
    cpf: "",
    phone: "",
    status: "",
  };
}

export default function NewFilter({
  filter,
  filterData,
  setFilterData,
  clearFilters,
}: OccurrenceFilterProps) {
  const [activeFilters, setActiveFilters] = useState(0);
  const [showFilter, setShowFilter] = useState(false);

  function handleFilterChange(
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    setFilterData({
      ...filterData,
      [ev.target.name]: ev.target.value,
    });
  }

  function getTotalActiveFilters() {
    let totalActiveFilters = 0;

    if (filterData.name) totalActiveFilters += 1;
    if (filterData.cpf) totalActiveFilters += 1;
    if (filterData.phone) totalActiveFilters += 1;
    if (filterData.status) totalActiveFilters += 1;

    return totalActiveFilters;
  }

  function handleClearFilters() {
    const initalData = getInitialFilterData();
    setFilterData(initalData);
    setActiveFilters(0);
    clearFilters();
  }

  return (
    <div className={styles.container}>
      <header
        className={styles.header}
        onClick={() => setShowFilter(!showFilter)}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ position: "relative" }}>
            {activeFilters > 0 && (
              <div className={`${styles.filtersBadge} primary on-primary-text`}>
                {activeFilters}
              </div>
            )}
            <FilterListIcon color="secondary" />
          </div>
          <h2 style={{ display: "flex", marginLeft: "10px", fontSize: "18px" }}>
            Filtros avançados
          </h2>
        </div>
        {showFilter ? (
          <ArrowDropUp style={{ width: "32px", height: "32px" }} />
        ) : (
          <ArrowDropDown style={{ width: "32px", height: "32px" }} />
        )}
      </header>
      <form className={`${showFilter ? styles.showFilters : styles.filters}`}>
        <Grid2 container spacing={1} sx={{ marginTop: "2px" }}>
          {/* <FormLabel className={styles.label}>Nome</FormLabel> */}
          <Grid2 xs={12} sm={6}>
            <TextField
              fullWidth
              size="small"
              label="Nome"
              name="name"
              value={filterData.name}
              onChange={handleFilterChange}
            />
          </Grid2>
          {/* <FormLabel className={styles.label}>CPF</FormLabel> */}
          <Grid2 xs={12} sm={6}>
            <TextField
              fullWidth
              size="small"
              label="CPF"
              name="cpf"
              value={filterData.cpf}
              onChange={handleFilterChange}
            />
          </Grid2>
          <Grid2 xs={12} sm={6}>
            {/* <FormLabel className={styles.label}>Telefone</FormLabel> */}
            <TextField
              fullWidth
              size="small"
              name="phone"
              label="Telefone"
              placeholder="Ex: +55(11)91234-5678"
              helperText="Para retornar todos números de SP capital, use: +55(11)"
              value={filterData.phone}
              onChange={handleFilterChange}
            />
          </Grid2>
          <Grid2 xs={12} sm={6}>
            {/* <FormLabel className={styles.label}>Status</FormLabel> */}
            <TextField
              select
              fullWidth
              size="small"
              name="status"
              label="Status"
              className={styles.formField}
              value={filterData.status}
              onChange={handleFilterChange}
            >
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value="0">Pendente</MenuItem>
              <MenuItem value="1">Pago</MenuItem>
            </TextField>
          </Grid2>
        </Grid2>
        <section className={styles.actions}>
          <Button onClick={handleClearFilters} color="error">
            Limpar filtros
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              filter(filterData);
              setActiveFilters(getTotalActiveFilters());
            }}
          >
            Filtrar
          </Button>
        </section>
      </form>
    </div>
  );
}
