import axios from "axios";

const rootApi = axios.create({
  baseURL: "https://www.choppbot.com.br/",
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer ",
  },
});

// const fetcher = (url: string) => rootApi.get(url).then((res) => res.data);

// rootApi.interceptors.request.use(
//   async (config) => {
//     //config.baseURL = `${window.location.host}/api`
//     // production
//     config.baseURL = `https://www.choppbot.com.br/api`;

//     return config;
//   },
//   async (error) => {
//     return Promise.reject(error);
//   },
// );

rootApi.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log("Erro interceptado");
    const response = error.response;
    console.log(error.response);
    if (response) {
      const status = response.status;
      if (status === 401 && window.location.pathname !== "/auth/login") {
        localStorage.clear();
        sessionStorage.clear();
        window.location.replace("/");
      }
    }
    return Promise.reject(error);
  },
);

export default rootApi;
