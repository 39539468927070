import {
  DeleteOutline,
  EditOutlined,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import {
  Alert,
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import React, { useState } from "react";
import styles from "./advertisementSet.module.css";
import { ChoppbotAd } from "@/helpers/interfaces/advertisements";
import { deleteAd } from "@/helpers/api/advertisements";
import useSWR from "swr";
import { useNavigate } from "react-router-dom";

/** Should be used inside a Gridv2 container */
export default function AdvertisementSet({
  ad,
  border,
  checked,
  onAssociate,
}: {
  ad: ChoppbotAd;
  checked?: boolean;
  border?: any;
  onAssociate?: () => Promise<void>;
}) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const { data, mutate } = useSWR<ChoppbotAd[]>("/api/advertising");

  function handleClose(event: Event | React.SyntheticEvent, reason?: string) {
    if (reason === "clickaway") {
      return;
    }

    setOpenError(true);
  }

  async function handleDelete() {
    await deleteAd(ad.id)
      .then((response) => {
        const newData = [...data!];
        const removedDataIndex = newData.findIndex(
          (value) => value.id === ad.id
        );

        newData.splice(removedDataIndex, 1);
        mutate(newData);
        setOpen(false);
      })
      .catch((e) => console.error(e));
  }

  return (
    <Grid2 xs={12} md={6} border={border}>
      <Card>
        <CardHeader
          title={ad.title}
          subheader={
            "Intervalo entre propagandas: " + ad.interval.toString() + "s"
          }
          action={
            <>
              <IconButton onClick={() => navigate(`/ads/edit-ad/${ad.id}`)}>
                <EditOutlined />
              </IconButton>
              {onAssociate ? (
                <IconButton
                  onClick={(ev) => {
                    ev.stopPropagation();
                    onAssociate();
                  }}
                >
                  {checked ? <RadioButtonChecked /> : <RadioButtonUnchecked />}
                </IconButton>
              ) : (
                <IconButton
                  onClick={(ev) => {
                    ev.stopPropagation();
                    setOpen(true);
                  }}
                >
                  <DeleteOutline color="error" />
                </IconButton>
              )}
            </>
          }
        />
        <div className={styles.adImagesContainer}>
          {ad.image_urls.map((src, index) => (
            <img
              key={index}
              src={src}
              width="210px"
              height="150px"
              style={{ marginRight: "10px" }}
              alt={`propaganda ${index}`}
            />
          ))}
        </div>
      </Card>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          Tem certeza que deseja excluir o conjunto "{ad.title}"?
        </DialogTitle>
        <DialogContent>
          Essa ação irá excluir o conjunto mencionado permanentemente.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete}>Sim</Button>
          <Button color="error" onClick={() => setOpen(false)}>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={openError} onClose={handleClose} autoHideDuration={3000}>
        <Alert severity="error" onClose={handleClose}>
          Um erro inesperado ocorreu.
        </Alert>
      </Snackbar>
    </Grid2>
  );
}
