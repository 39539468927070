import FlashMessage from "@/components/FlashMessage";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import EditEventBeer from "@/components/Modals/EditEventBeer";
import MainContainer from "@/components/mainContainer/MainContainer";
import { deleteBeer, getBeersByEvent } from "@/helpers/api/beers";
import { eventStatusTranslation } from "@/helpers/eventStatusTranslation";
import { ChoppbotBeer } from "@/helpers/interfaces/beer";
import {
  DeleteOutline,
  EditOutlined,
  MoreVert,
  SportsBar,
} from "@mui/icons-material";
import { CircularProgress, IconButton, Menu, MenuItem } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import styles from "./event-beers.module.css";

export default function EventBeers() {
  const params = useParams();
  const {
    data: beers,
    isLoading,
    error,
    mutate,
  } = useSWR([`api/beersByEvent`, params.eventId], ([, eventId]) =>
    getBeersByEvent(parseInt(eventId!))
  );
  const eventInfo = useMemo(
    () => (beers && beers.data.length > 0 ? beers.data[0].event : null),
    [beers]
  );
  const [selectedBeer, setSelectedBeer] = useState<ChoppbotBeer | null>(null);
  const [editEvent, setEditEvent] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [flashMessage, setFlashMessage] = useState(false);

  async function handleDeleteBeer(beer: ChoppbotBeer) {
    await deleteBeer(beer.id)
      .then(() => {
        mutate();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (isLoading) {
    return (
      <MainContainer>
        <CircularProgress />
      </MainContainer>
    );
  }

  if (error || beers?.data.length === 0) {
    return (
      <MainContainer>
        <h2 style={{ marginBottom: "12px" }}>Cervejas</h2>
        {error ? (
          <p>Erro ao obter dados</p>
        ) : (
          <p>
            Esse evento ainda não possui cerevejas cadastradas. Por favor, crie
            as cervejas na tela de eventos.
          </p>
        )}
      </MainContainer>
    );
  }

  return (
    <MainContainer>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <h1 style={{ fontSize: "24px", fontWeight: 400 }}>
          Cervejas - Evento {eventInfo?.name}
        </h1>
        <div
          style={{
            width: "150px",
            height: "30px",
            borderRadius: "10px",
            backgroundColor:
              eventInfo?.status === "activated" ? "#74D47ED1" : "#F5C387D1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p>{eventStatusTranslation(eventInfo?.status)}</p>
        </div>
      </div>
      <div className={styles.gridContainer}>
        {beers?.data.map((_beer, idx) => (
          <BeerCard
            key={idx}
            beer={_beer}
            onEdit={() => {
              setSelectedBeer(_beer);
              setEditEvent(true);
            }}
            onRemove={() => {
              setSelectedBeer(_beer);
              setConfirmDelete(true);
            }}
          />
        ))}
      </div>
      {selectedBeer && (
        <EditEventBeer
          modal={editEvent}
          beer={selectedBeer}
          title={selectedBeer.event.name}
          close={() => {
            setEditEvent(false);
            setSelectedBeer(null);
          }}
          send={(success) => {
            setEditEvent(false);
            if (success) {
              mutate();
              setFlashMessage(true);
            }
            setSelectedBeer(null);
            // TODO: error snackbar
          }}
        />
      )}
      {selectedBeer && (
        <ConfirmModal
          open={confirmDelete}
          onSubmit={() => handleDeleteBeer(selectedBeer)}
          onClose={() => {
            setSelectedBeer(null);
            setConfirmDelete(false);
          }}
          title={`Tem certeza que deseja remover a cerveja ${selectedBeer.beer_name}?`}
        />
      )}
      {flashMessage && (
        <FlashMessage
          message={"Atualização salva com sucesso"}
          title={"Sucesso"}
          type={"success"}
        />
      )}
    </MainContainer>
  );
}

function BeerCard({
  beer,
  onEdit,
  onRemove,
}: {
  beer: ChoppbotBeer;
  onEdit: () => void;
  onRemove: () => void;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      key={beer.id}
      style={{
        display: "flex",
        padding: "12px",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: "12px",
        border: "1px solid #aaaaaa",
      }}
    >
      <SportsBar fontSize="large" />
      <div style={{ paddingLeft: "12px" }}>
        <h3 style={{ marginBottom: "4px" }}>{beer.beer_name}</h3>
        <p>Preço/litro: R$ {beer.price_liters.toFixed(2)}</p>
        <p>Preço/unidade: R$ {beer.price_unit.toFixed(2)}</p>
      </div>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            onEdit();
            handleClose();
          }}
        >
          <EditOutlined sx={{ marginRight: "4px" }} />
          Editar
        </MenuItem>
        <MenuItem
          onClick={() => {
            onRemove();
            handleClose();
          }}
        >
          <DeleteOutline color="error" sx={{ marginRight: "4px" }} />
          Remover
        </MenuItem>
      </Menu>
    </div>
  );
}
