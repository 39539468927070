import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import {
  associateAd,
  createAdAssociation,
  getMachine,
} from "@/helpers/api/machines";
import {
  Alert,
  CircularProgress,
  Snackbar,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { ChoppbotMachine } from "@/helpers/interfaces/machine";
import AdvertisementSet from "@/components/advertisementSet/AdvertisementSet";
import styles from "./machine.module.css";
import { adsFetcher } from "@/helpers/api/advertisements";
import { ChoppbotAd } from "@/helpers/interfaces/advertisements";
import MainContainer from "@/components/mainContainer/MainContainer";

const initialFormData: Partial<ChoppbotMachine> = {
  description: "",
  ipv4: "",
  anydesk: "",
};

export default function Machine() {
  const params = useParams();
  const { data, mutate, isLoading } = useSWR<ChoppbotMachine>(
    `/api/machine/${params.machineId}`,
    getMachine
  );
  const { data: advertisements } = useSWR<ChoppbotAd[]>(
    "/api/advertising",
    adsFetcher
  );
  const [formData, setFormData] = useState<Partial<ChoppbotMachine>>(
    data ?? initialFormData
  );
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (data && formData !== data) setFormData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  async function handleAssociateAd(adId: number) {
    if (!data) return;

    if (data && !data.advertising_machine) {
      await createAdAssociation({
        advertising_id: adId,
        machine_id: parseInt(params.machineId!),
      })
        .then((response) => {
          mutate();
          setSuccess(true);
        })
        .catch((error) => {
          setError(true);
        });
    } else {
      await associateAd({
        advertising_id: adId,
        machine_id: data?.advertising_machine.id!,
      })
        .then((response) => {
          mutate();
          setSuccess(true);
        })
        .catch((error) => {
          setError(true);
        });
    }
  }

  function handleClose(event: React.SyntheticEvent | Event, reason?: string) {
    if (reason === "clickaway") {
      return;
    }

    setSuccess(false);
    setError(false);
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  }

  if (isLoading)
    return (
      <MainContainer>
        <h2 style={{ marginBottom: "12px" }}>Detalhes da máquina</h2>
        <CircularProgress />
      </MainContainer>
    );
  return (
    <MainContainer>
      <h2 style={{ marginBottom: "12px" }}>Detalhes da máquina</h2>
      <form className={styles.form}>
        <TextField
          disabled
          label="Nome"
          name="description"
          value={formData.description}
          onChange={handleChange}
        />
        <TextField
          disabled
          label="IPv4"
          name="ipv4"
          value={formData.ipv4}
          onChange={handleChange}
        />
        <TextField
          disabled
          label="Anydesk"
          name="anydesk"
          value={formData.anydesk}
          onChange={handleChange}
        />
        {/* <Button variant="contained">Salvar</Button> */}
      </form>
      <h2 style={{ marginBlock: "15px" }}>Propagandas</h2>
      <Grid container spacing={2}>
        {advertisements?.map((ad, index) => (
          <AdvertisementSet
            ad={ad}
            key={index}
            checked={data?.advertising_machine?.advertising.id === ad.id}
            onAssociate={() => handleAssociateAd(ad.id)}
          />
        ))}
      </Grid>
      <Snackbar
        open={success}
        onClose={handleClose}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="success" onClose={handleClose}>
          Propaganda associada com sucesso!
        </Alert>
      </Snackbar>
      <Snackbar
        open={error}
        onClose={handleClose}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error" onClose={handleClose}>
          Um erro inesperado ocorreu
        </Alert>
      </Snackbar>
    </MainContainer>
  );
}
