import { createContext } from "react";

interface MainContext {
  user: any;
  setUser: (value: any) => void;
  baseURL: string;
  setBaseURL: (value: string) => void;
}

const initialContext: MainContext = {
  user: null,
  setUser: (value: any) => {},
  baseURL: "",
  setBaseURL: (value: string) => {},
};

const LoginAuth = createContext(initialContext);

export default LoginAuth;
