import { useContext } from "react";
import { redirect } from "react-router-dom";
import LoginAuth from "../../helpers/context/LoginContext";

export default function Private({ children }: { children: React.ReactNode }) {
  const { user } = useContext(LoginAuth);

  if (!user) {
    redirect("/auth/login");
  }

  return <>{children}</>;
}
