import rootApi from "./rootConfig";

export async function getMachines() {
  const response = await rootApi.get("/api/machine");

  return response.data.data;
}

export async function getMachine(path: string) {
  const response = await rootApi.get(path);

  return response.data.data;
}

export async function createAdAssociation(data: {
  advertising_id: number;
  machine_id: number;
}) {
  return await rootApi.post("api/advertising_machine", data);
}

export async function associateAd(data: {
  advertising_id: number;
  machine_id: number;
}) {
  return await rootApi.put("api/advertising_machine/" + data.machine_id, {
    advertising_id: data.advertising_id,
  });
}
