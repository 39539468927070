import MainContainer from "@/components/mainContainer/MainContainer";
import styles from "./Dashboard.module.css";

export default function Dashboard() {
  return (
    <MainContainer>
      <div className={styles.container}>
        <img
          src="/assets/logo.png"
          style={{
            maxWidth: "650px",
            minHeight: "200px",
            minWidth: "200px",
            maxHeight: "95%",
          }}
          width="70%"
          height="auto"
          alt="choppbot"
        />
      </div>
    </MainContainer>
  );
}
