import { ChoppbotEvent } from "@/helpers/interfaces/events";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";

interface SyncEventDialogProps {
  open: boolean;
  event: ChoppbotEvent | null;
  onClose: () => void;
}

export default function SyncEventDialog({
  open,
  event,
  onClose,
}: SyncEventDialogProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Sincronização do evento {event?.name}</DialogTitle>
      <DialogContent>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      </DialogContent>
    </Dialog>
  );
}
