import { ChoppbotEvent } from "../interfaces/events";
import { BackendResponsePagination } from "../interfaces/generic";
import rootApi from "./rootConfig";

export async function createEvent(data: FormData) {
  return await rootApi
    .post("api/event", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res.data);
}

export async function putEvent(eventId: number, data: FormData) {
  return await rootApi
    .post("api/event/" + eventId + "?_method=PUT", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res.data);
}

export async function getEvents() {
  return await rootApi
    .get<BackendResponsePagination<ChoppbotEvent>>("api/event")
    .then((res) => res.data);
}

export async function resetEventReceipts(eventId: number) {
  return await rootApi
    .get(`api/event/${eventId}/reset_reports`)
    .then((res) => res.data);
}

export async function synchronizeChoppbotEvent(
  eventId: number,
  franchiseId: number
) {
  return await rootApi
    .get(`api/customer_sync_external_customer`, {
      timeout: 120000,
      headers: {
        "Token-External": "base64:c/UmY/eD44gjeRHfPY1cKhmK8XyAgHgEYcM1p/1XRU0=",
        Event: eventId,
        Franchisee: franchiseId,
      },
    })
    .then((res) => res.data);
}
