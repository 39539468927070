import { ChoppbotBeer } from "../interfaces/beer";
import { BackendResponsePagination } from "../interfaces/generic";
import rootApi from "./rootConfig";

export async function getBeersByEvent(eventId: number) {
  return await rootApi
    .get<BackendResponsePagination<ChoppbotBeer>>(
      "api/beersByEvent/" + eventId + "?page=1",
      {
        headers: {
          paginate: 100000,
        },
      }
    )
    .then((res) => res.data);
}

export async function deleteBeer(beerId: number) {
  return await rootApi.delete("api/beer/" + beerId).then((res) => res.data);
}
