import { ChoppbotCustomer } from "./interfaces/customers";

function convertToCSV(customers: ChoppbotCustomer[]) {
  const header = [
    "Nome",
    "N° comanda",
    "Cpf",
    "Empresa",
    "Cargo",
    "Email",
    "Telefone",
    "Pagamento",
  ];
  const csvRows = [header.join(",")];

  customers.forEach((customer) => {
    // Ensure names containing commas are wrapped in double quotes
    const nameField = customer.name.includes(",")
      ? `"${customer.name}"`
      : customer.name;

    const row = [
      nameField,
      customer.client_code || "",
      customer.identifier || "",
      customer.company,
      customer.profession,
      customer.email,
      customer.phone,
      customer.payment === 0 ? "Pendente" : "Pago",
    ];
    csvRows.push(row.join(","));
  });

  return csvRows.join("\n");
}

export function downloadCSV(customers: ChoppbotCustomer[]) {
  const csvData = convertToCSV(customers);

  const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "comandas.csv");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
