import React from "react";
import { formatBytes } from "@/helpers/formatBytes";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Tooltip,
} from "@mui/material";

function clipEllipsis(text: string) {
  if (text.length > 22) {
    return text.slice(0, 19) + "...";
  }

  return text;
}

export default function FileCard({
  file,
  onRemove,
}: {
  file: File;
  onRemove: () => void;
}) {
  return (
    <Card style={{ width: "310px", height: "280px" }}>
      <CardHeader
        title={
          <Tooltip title={file.name} placement="top">
            <h4>{clipEllipsis(file.name)}</h4>
          </Tooltip>
        }
        style={{ paddingBottom: "0px" }}
        subheader={formatBytes(file.size)}
        action={
          <IconButton onClick={onRemove}>
            <DeleteIcon color="error" fontSize="small" />
          </IconButton>
        }
      />
      <CardContent className="pt-0" style={{ backgroundColor: "white" }}>
        <img
          src={URL.createObjectURL(file)}
          style={{ width: "270px", height: "170px" }}
          alt="propaganda"
          // className="w-52 h-32"
        />
      </CardContent>
    </Card>
  );
}
