import { ChoppbotFranchisee } from "./franchisee";

export interface ChoppbotEvent {
  id: number;
  franchisee: ChoppbotFranchisee;
  name: string;
  description: string | null;
  link: string;
  collection: string;
  category: "open" | "prepaid" | "postpaid";
  limit_beer: number;
  started_at: string;
  finished_at: string | null;
  status: "created" | "activated";
  form_fields: ChoppbotEventFormFields | null;
}

interface ChoppbotEventFormFields {
  face: boolean;
  name: boolean;
  identifier: boolean;
  phone: boolean;
  email: boolean;
  company: boolean;
  profession: boolean;
  office: boolean;
  state: boolean;
  city: boolean;
  client_code: boolean;
}

export enum ChoppbotEventFormField {
  face = "Foto",
  name = "Nome",
  identifier = "CPF",
  phone = "Telefone",
  email = "Email",
  company = "Empresa",
  profession = "Profissão",
  office = "Setor",
  state = "Estado",
  city = "Cidade",
  clientCode = "Código de cliente",
}
