import React, {
  useState,
  useContext,
  useRef,
  useMemo,
  useCallback,
} from "react";
import LoginAuth from "@/helpers/context/LoginContext";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import AddEventBeer from "@/components/Modals/AddEventBeer";
import AddEventModal from "@/components/Modals/AddEvent";
import { DataGrid } from "@mui/x-data-grid";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
} from "@mui/material";
import { Add, Download } from "@mui/icons-material";
import styles from "./Eventos.module.css";
import MainContainer from "@/components/mainContainer/MainContainer";
import { useNavigate } from "react-router-dom";
import rootApi from "@/helpers/api/rootConfig";
import { ChoppbotEvent } from "@/helpers/interfaces/events";
import { eventsColumns } from "./eventsColumns";
import { deleteAllCustomersFromEvent } from "@/helpers/api/customers";
import useSWR from "swr";
import { BackendResponsePagination } from "@/helpers/interfaces/generic";
import QRCode from "react-qr-code";
import { gridLocale } from "@/helpers/interfaces/datagridLocale";
import { downloadSVG } from "@/helpers/downloadQrCode";
import {
  resetEventReceipts,
  synchronizeChoppbotEvent,
} from "@/helpers/api/events";
import SyncEventDialog from "@/components/Modals/SyncEventDialog";

const eventsFetcher = async () => {
  return await rootApi
    .get<BackendResponsePagination<ChoppbotEvent>>("api/event")
    .then((response) => response.data);
};

export default function Home() {
  const navigate = useNavigate();
  const { user, baseURL } = useContext(LoginAuth);
  const {
    data: events,
    isLoading,
    mutate,
  } = useSWR(`/api/event`, eventsFetcher);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [addBeer, setAddBeer] = useState(false);
  const [creatEvent, setCreateEvent] = useState(false);
  const [editEvent, setEditEvent] = useState(false);
  const [openDeleteCustomers, setOpenDeleteCustomers] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<ChoppbotEvent | null>(
    null
  );
  const [openResetReceipts, setOpenResetReceipts] = useState(false);
  const [openSyncEvent, setOpenSyncEvent] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showQrCode, setShowQrCode] = useState(false);
  const [flashMessage1, setFlashMessage1] = useState(false);
  const [editSuccess, setEditSuccess] = useState(false);
  const [editError, setEditError] = useState(false);
  const [flashMessage3, setFlashMessage3] = useState(false);
  const [flashMessage4, setFlashMessage4] = useState(false);
  const [syncEventSuccess, setSyncEventSuccess] = useState(false);
  const [syncEventError, setSyncEventError] = useState(false);
  const [deleteCustomersSuccess, setDeleteCustomersSuccess] = useState(false);
  const [deleteCustomersError, setDeleteCustomersError] = useState(false);
  const [resetReceiptsSuccess, setResetReceiptsSuccess] = useState(false);
  const [resetReceiptsError, setResetReceiptsError] = useState(false);
  const svgRef = useRef<SVGSVGElement>(null);
  const handleEditEvent = useCallback((event: ChoppbotEvent) => {
    setSelectedEvent(event);
    setEditEvent(true);
  }, []);

  const handleEditBeers = useCallback((event: ChoppbotEvent) => {
    setSelectedEvent(event);
    setAddBeer(true);
  }, []);

  const handleDeleteEvent = useCallback((event: ChoppbotEvent) => {
    setSelectedEvent(event);
    setConfirmDelete(true);
  }, []);

  const handleShowQrCode = useCallback((event: ChoppbotEvent) => {
    setSelectedEvent(event);
    setShowQrCode(true);
  }, []);

  const handleDeleteCustomers = useCallback((event: ChoppbotEvent) => {
    setSelectedEvent(event);
    setOpenDeleteCustomers(true);
  }, []);

  const handleResetReceipts = useCallback((event: ChoppbotEvent) => {
    setSelectedEvent(event);
    setOpenResetReceipts(true);
  }, []);

  const handleSyncEvent = useCallback(async (event: ChoppbotEvent) => {
    setSelectedEvent(event);
    setOpenSyncEvent(true);
    await synchronizeChoppbotEvent(event.id, event.franchisee.id)
      .then(() => {
        setSyncEventSuccess(true);
      })
      .catch(() => {
        setSyncEventError(true);
      });
    setOpenSyncEvent(false);
  }, []);

  const columns = useMemo(
    () =>
      eventsColumns({
        onEditEvent: handleEditEvent,
        onSyncEvent: handleSyncEvent,
        onEditBeers: handleEditBeers,
        onShowQrCode: handleShowQrCode,
        onDeleteEvent: handleDeleteEvent,
        onResetReceipts: handleResetReceipts,
        onDeleteCustomers: handleDeleteCustomers,
        goToReceipts: (event) => navigate(`/receipts/${event.id}`),
      }),
    [
      navigate,
      handleEditBeers,
      handleEditEvent,
      handleSyncEvent,
      handleShowQrCode,
      handleDeleteEvent,
      handleResetReceipts,
      handleDeleteCustomers,
    ]
  );

  const deleteEventFunc = async () => {
    const options = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      },
    };

    setIsDeleting(true);
    await fetch(baseURL + "/api/event/" + selectedEvent?.id, options)
      .then((response) => {
        mutate();
        response.json();
        setFlashMessage4(true);
      })

      .catch((err) => console.error(err));
    setIsDeleting(false);
  };

  async function deleteAllCustomersFromEventHandler() {
    setIsDeleting(true);
    await deleteAllCustomersFromEvent(selectedEvent!.id)
      .then(() => {
        setOpenDeleteCustomers(false);
        setSelectedEvent(null);
        setDeleteCustomersSuccess(true);
      })
      .catch(() => {
        setOpenDeleteCustomers(false);
        setSelectedEvent(null);
        setDeleteCustomersError(true);
      });
    setIsDeleting(false);
  }

  async function resetReceiptsFromEvent() {
    setIsDeleting(true);
    await resetEventReceipts(selectedEvent!.id)
      .then(() => {
        setOpenResetReceipts(false);
        setSelectedEvent(null);
        setResetReceiptsSuccess(true);
      })
      .catch(() => {
        setOpenDeleteCustomers(false);
        setSelectedEvent(null);
        setResetReceiptsError(true);
      });
    setIsDeleting(false);
  }

  function handleDownloadQrCode() {
    downloadSVG(svgRef.current, selectedEvent?.name);
  }

  return (
    <MainContainer>
      <div className={styles.headerContainer}>
        <h1 style={{ fontSize: "24px", fontWeight: 400 }}>Lista de eventos</h1>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            setCreateEvent(true);
          }}
          startIcon={<Add />}
        >
          Novo evento
        </Button>
      </div>
      <div className={styles.tableContainer}>
        <DataGrid
          autoHeight
          columns={columns}
          loading={isLoading}
          localeText={gridLocale}
          rows={events?.data || []}
          disableRowSelectionOnClick
        />
      </div>
      {!!selectedEvent && (
        <AddEventBeer
          open={addBeer}
          onClose={() => {
            setAddBeer(false);
            // Timeout to avoid canceling dialog animation
            setTimeout(() => setSelectedEvent(null), 400);
          }}
          onSubmit={() => {
            setAddBeer(false);
            setFlashMessage3(true);
            // Timeout to avoid canceling dialog animation
            setTimeout(() => {
              setSelectedEvent(null);
            }, 400);
          }}
          eventID={selectedEvent.id}
          title={selectedEvent.name}
        />
      )}
      {creatEvent && (
        <AddEventModal
          open={creatEvent}
          close={() => setCreateEvent(false)}
          onSubmit={(success) => {
            setCreateEvent(false);
            if (success) {
              mutate();
              setFlashMessage1(true);
            }
            // TODO: error snackbar
          }}
          eventId={null}
          prevFormFields={null}
          eventName=""
          eventType=""
          typeSend="POST"
          descriptionName=""
          title="Crie seu evento"
        />
      )}
      <SyncEventDialog
        open={openSyncEvent}
        event={selectedEvent}
        onClose={() => {
          // empty
          // This is intended, since it prevents the user from closing the dialog
        }}
      />
      {editEvent && !!selectedEvent && (
        <AddEventModal
          open={editEvent}
          close={() => setEditEvent(false)}
          onSubmit={(success) => {
            setEditEvent(false);
            if (success) {
              mutate();
              setEditSuccess(true);
            } else {
              setEditError(true);
            }
          }}
          eventName={selectedEvent.name}
          prevFormFields={selectedEvent.form_fields}
          descriptionName={selectedEvent.description}
          eventType={selectedEvent.category}
          eventId={selectedEvent.id}
          title="Edite o evento"
          typeSend="PUT"
        />
      )}
      {confirmDelete && (
        <ConfirmModal
          open={confirmDelete}
          isLoading={isDeleting}
          onSubmit={deleteEventFunc}
          onClose={() => {
            setConfirmDelete(false);
            setSelectedEvent(null);
          }}
          title={`Tem certeza que deseja remover o evento ${selectedEvent?.name}?`}
        />
      )}
      {openDeleteCustomers && (
        <ConfirmModal
          isLoading={isDeleting}
          open={openDeleteCustomers}
          onSubmit={deleteAllCustomersFromEventHandler}
          onClose={() => {
            setOpenDeleteCustomers(false);
            setSelectedEvent(null);
          }}
          title={`Tem certeza que deseja resetar o evento ${selectedEvent?.name}?`}
          description="Essa ação irá remover todos os clientes e seus respectivos dados do evento"
        />
      )}
      {openResetReceipts && (
        <ConfirmModal
          isLoading={isDeleting}
          open={openResetReceipts}
          onSubmit={resetReceiptsFromEvent}
          onClose={() => {
            setOpenResetReceipts(false);
            setSelectedEvent(null);
          }}
          title={`Tem certeza que deseja resetar as comandas do evento ${selectedEvent?.name}`}
          description="Essa ação irá remover todas as informações de consumo de cada cliente do evento"
        />
      )}
      {selectedEvent && (
        <Dialog
          open={showQrCode}
          onClose={() => {
            setShowQrCode(false);
            setSelectedEvent(null);
          }}
        >
          <DialogTitle>
            QR Code para o formulário do evento {selectedEvent.name}
          </DialogTitle>
          <DialogContent>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <QRCode
                // @ts-expect-error
                ref={(value: SVGSVGElement) => {
                  // @ts-expect-error
                  svgRef.current = value;
                }}
                id="event-qr-code"
                size={250}
                value={selectedEvent.link}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              color="inherit"
              onClick={() => {
                setShowQrCode(false);
                setSelectedEvent(null);
              }}
            >
              Voltar
            </Button>
            <Button
              color="primary"
              startIcon={<Download />}
              onClick={handleDownloadQrCode}
            >
              Baixar QR Code
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Snackbar
        open={syncEventSuccess}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setSyncEventSuccess(false)}
      >
        <Alert severity="success" onClose={() => setSyncEventSuccess(false)}>
          Evento sincronizado com sucesso
        </Alert>
      </Snackbar>
      <Snackbar
        open={syncEventError}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setSyncEventError(false)}
      >
        <Alert severity="error" onClose={() => setSyncEventError(false)}>
          Erro ao sincronizar evento
        </Alert>
      </Snackbar>
      <Snackbar
        open={flashMessage1}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setFlashMessage1(false)}
      >
        <Alert severity="success" onClose={() => setFlashMessage1(false)}>
          Evento criado com sucesso
        </Alert>
      </Snackbar>
      <Snackbar
        open={editSuccess}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setEditSuccess(false)}
      >
        <Alert severity="success" onClose={() => setEditSuccess(false)}>
          Atualização salva com sucesso
        </Alert>
      </Snackbar>
      <Snackbar
        open={editError}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setEditError(false)}
      >
        <Alert severity="error" onClose={() => setEditError(false)}>
          Erro ao atualizar dados
        </Alert>
      </Snackbar>
      <Snackbar
        open={flashMessage3}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setFlashMessage3(false)}
      >
        <Alert severity="success" onClose={() => setFlashMessage3(false)}>
          Cervejas criadas com sucesso
        </Alert>
      </Snackbar>
      <Snackbar
        open={flashMessage4}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setFlashMessage4(false)}
      >
        <Alert severity="success" onClose={() => setFlashMessage4(false)}>
          Evento excluído com sucesso
        </Alert>
      </Snackbar>
      <Snackbar
        open={deleteCustomersSuccess}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setDeleteCustomersSuccess(false)}
      >
        <Alert
          severity="success"
          onClose={() => setDeleteCustomersSuccess(false)}
        >
          Clientes removidos com sucesso
        </Alert>
      </Snackbar>
      <Snackbar
        open={resetReceiptsSuccess}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setResetReceiptsSuccess(false)}
      >
        <Alert
          severity="success"
          onClose={() => setResetReceiptsSuccess(false)}
        >
          Comandas resetadas com sucesso
        </Alert>
      </Snackbar>
      <Snackbar
        open={resetReceiptsError}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setResetReceiptsError(false)}
      >
        <Alert severity="error" onClose={() => setResetReceiptsError(false)}>
          Erro ao resetar comandas
        </Alert>
      </Snackbar>
      <Snackbar
        open={deleteCustomersError}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setDeleteCustomersError(false)}
      >
        <Alert severity="error" onClose={() => setDeleteCustomersError(false)}>
          Erro ao remover clientes
        </Alert>
      </Snackbar>
    </MainContainer>
  );
}
