import { Fragment, useMemo } from "react";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import styles from "./CustomerModal.module.css";
import { BeerReport } from "@/helpers/interfaces/beerReport";

function CustomerModal({
  open,
  onClose,
  onSend,
  beerReport,
}: {
  open: boolean;
  onClose: () => void;
  onSend: (customerId: number, eventId: number, isPaid: boolean) => void;
  beerReport: BeerReport;
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const totalPrice = useMemo(() => {
    let total = 0;
    beerReport.beers.forEach((beer) => {
      total += beer.total_cost_liters;
    });
    return total.toFixed(2);
  }, [beerReport.beers]);
  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={onClose}
        fullScreen={fullScreen}
      >
        <DialogTitle>
          Comanda{" "}
          {beerReport.customer.client_code
            ? `n° ${beerReport.customer.client_code}`
            : ""}{" "}
          de {beerReport.customer.customer_name}
        </DialogTitle>
        <DialogContent>
          <div className={styles.header}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={beerReport.customer.face}
                alt={beerReport.customer.customer_name}
                width={52}
                height={52}
                style={{
                  borderRadius: 9999,
                  marginRight: "12px",
                }}
              />
              <span>{beerReport.customer.customer_name}</span>
            </div>
            <Chip
              label={
                beerReport.customer.payment === 0
                  ? "Pagamento pendente"
                  : "Pago"
              }
              color={beerReport.customer.payment === 0 ? "warning" : "success"}
            />
          </div>
          <div className={styles.tableBeer}>
            <span className={styles.columnBeer}>Bebida</span>
            <span className={styles.columnBeer}>R$/Litro</span>
            <span className={styles.columnBeer}>Quantidade (L)</span>
            <span className={styles.columnBeer}>Subtotal (R$)</span>
          </div>
          <div className={styles.divider} />
          <div
            className={
              beerReport.beers.length > 0
                ? styles.tableBeer
                : styles.tableBeerEmpty
            }
            style={{ height: "300px" }}
          >
            <div className={styles.rowBoxDiv}>
              {beerReport.beers.length > 0 ? (
                beerReport.beers.map((beer, index) => (
                  <Fragment key={index}>
                    <div className={styles.rowBox}>
                      {/* <img src={beerExample} style={{position: "absolute", left: 0}}/> */}
                      <span className={styles.rowBeer}>{beer.beer_name}</span>
                      <span className={styles.rowBeer}>
                        {beer.price_liters}
                      </span>
                      <span className={styles.rowBeer}>
                        {beer.total_liters}
                      </span>
                      <span className={styles.rowBeer}>
                        {beer.total_cost_liters}
                      </span>
                    </div>
                    <div className={styles.divider} />
                  </Fragment>
                ))
              ) : (
                <h1>Nenhuma cerveja registrada</h1>
              )}
            </div>
          </div>
          <div className={styles.totalValue}>
            <span>Total</span>
            <span>R${totalPrice}</span>
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={onClose}>
            Fechar
          </Button>
          <Button
            onClick={() => {
              onSend(
                beerReport.customer.customer_id,
                beerReport.customer.event_id,
                beerReport.customer.payment === 0 ? true : false
              );
            }}
          >
            {beerReport.customer.payment === 0
              ? "Encerrar comanda"
              : "Reabrir comanda"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CustomerModal;
