import AttachCard from "@/components/attachCard/AttachCard";
import FileCard from "@/components/fileCard/FileCard";
import MainContainer from "@/components/mainContainer/MainContainer";
import {
  Alert,
  Button,
  CircularProgress,
  Snackbar,
  TextField,
} from "@mui/material";
import React, { FormEvent, useEffect, useState } from "react";
import styles from "./createAd.module.css";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { ChoppbotAd } from "@/helpers/interfaces/advertisements";
import { adsFetcher, putAd } from "@/helpers/api/advertisements";
import useSWR from "swr";
import { useNavigate, useParams } from "react-router-dom";

interface FormDataErrors {
  title: boolean;
  interval: boolean;
  images: boolean;
}

interface ChoppbotAdFormData extends ChoppbotAd {
  "images[]": File[];
  videos: File[];
}

function handleFormDataErrors(
  formData: Partial<ChoppbotAdFormData>,
  setFormDataErrors: ({ title, interval, images }: FormDataErrors) => void
) {
  const newFormDataErrors = {
    title: false,
    interval: false,
    images: false,
  };

  if (!formData.title) newFormDataErrors.title = true;
  if (!formData.interval) newFormDataErrors.interval = true;
  if (formData["images[]"]?.length === 0) newFormDataErrors.images = true;

  setFormDataErrors(newFormDataErrors);
}

function canCreateAd(
  formData: Partial<ChoppbotAdFormData>,
  setFormDataErrors: React.Dispatch<
    React.SetStateAction<{
      title: boolean;
      interval: boolean;
      images: boolean;
    }>
  >
) {
  handleFormDataErrors(formData, setFormDataErrors);

  if (
    !formData.title ||
    !formData.interval ||
    formData.interval < 1 ||
    formData["images[]"]?.length === 0
  ) {
    return false;
  }

  return true;
}

export default function EditAd() {
  const params = useParams();
  const { data, isLoading, mutate } = useSWR<ChoppbotAd>(
    `/api/advertising/${params.id}`,
    adsFetcher
  );
  const navigate = useNavigate();
  const [formData, setFormData] = useState<Partial<ChoppbotAdFormData>>({
    title: "",
    interval: 3,
    description: "",
    "images[]": [],
    image_urls: [],
  });
  const [formDataErrors, setFormDataErrors] = useState({
    title: false,
    interval: false,
    images: false,
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const formDataImages = formData["images[]"];

  useEffect(() => {
    if (data) {
      setFormData({ ...data, "images[]": [] });
    }
  }, [data]);

  useEffect(() => {
    const newFormDataErrors = { ...formDataErrors };

    if (formDataImages && formDataImages.length > 0) {
      newFormDataErrors.images = false;
    }
    if (formData.title) newFormDataErrors.title = false;
    if (formData.interval && formData.interval > 0)
      newFormDataErrors.interval = false;

    setFormDataErrors(newFormDataErrors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.title, formData.interval, formDataImages]);

  function handleClose(event: React.SyntheticEvent | Event, reason?: string) {
    if (reason === "clickaway") {
      return;
    }

    setSuccess(false);
    setError(false);
  }

  function addFile(newFile: File) {
    const newFiles = [...formData["images[]"]!];
    newFiles.push(newFile);
    setFormData({ ...formData, "images[]": newFiles });
  }

  function removeFile(index: number) {
    const newFiles = [...formData["images[]"]!];
    newFiles.splice(index, 1);
    setFormData({ ...formData, "images[]": newFiles });
  }

  function handleChange(ev: React.ChangeEvent<HTMLInputElement>) {
    setFormData({
      ...formData,
      [ev.target.name]: ev.target.value,
    });
  }

  async function handleUpdateAd(ev: FormEvent) {
    ev.preventDefault();
    if (!canCreateAd(formData, setFormDataErrors)) return;

    const multipartData = new FormData();

    Object.keys(formData).forEach((key) => {
      if (key === "images[]") {
        formData["images[]"]!.forEach((image) =>
          multipartData.append(key, image)
        );
      } else {
        multipartData.append(key, formData[key as keyof ChoppbotAd] as string);
      }
    });

    await putAd(parseInt(params.id!), multipartData)
      .then((response) => {
        mutate();
        setSuccess(true);
        setTimeout(() => navigate("/ads"), 1500);
        console.log(response.data);
      })
      .catch((e) => {
        if (e.response && e.response.status === 422) {
          setErrorMessage(
            "Os tipos permitidos de imagem são: jpeg, png, jpg, gif"
          );
        } else {
          setErrorMessage("Um erro inesperado ocorreu");
        }
        setError(true);
      });
  }

  return (
    <MainContainer>
      <h2 style={{ marginBottom: "15px" }}>
        Criação de conjunto de propagandas
      </h2>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <form className={styles.form} onSubmit={handleUpdateAd} noValidate>
          <TextField
            required
            label="Nome"
            name="title"
            value={formData.title}
            error={formDataErrors.title}
            onChange={handleChange}
          />
          <TextField
            label="Descrição"
            name="description"
            onChange={handleChange}
          />
          <h3>Imagens *</h3>

          <Alert severity="info">
            Apenas os tipos .jpeg, .png e .jpg são suportados.
          </Alert>
          <h4 style={{ marginBottom: "10px" }}>
            Propagandas antes da edição: {formData.image_urls?.length}{" "}
            propaganda(s)
          </h4>
          <Grid2 container spacing={2}>
            {formData.image_urls?.map((imageUrl, index) => (
              <Grid2 key={index}>
                <img
                  src={imageUrl}
                  alt={`propaganda ${index + 1}`}
                  style={{ width: "135px", height: "85px" }}
                />
              </Grid2>
            ))}
          </Grid2>
          <h4 style={{ marginBottom: "10px" }}>Propagandas após a edição</h4>
          <Grid2 container spacing={2}>
            {formData["images[]"]?.map((file, index) => (
              <Grid2 key={index}>
                <FileCard file={file} onRemove={() => removeFile(index)} />
              </Grid2>
            ))}
          </Grid2>
          <AttachCard
            onChange={addFile}
            color={formDataErrors.images ? "error" : undefined}
          />
          {/* <h3>Videos</h3>
        <AttachCard onChange={() => {}} /> */}
          <h3>Intervalo entre propagandas</h3>
          <TextField
            required
            type="number"
            name="interval"
            value={formData.interval}
            label="Intervalo (s)"
            onChange={handleChange}
            error={formDataErrors.interval}
          />
          <Button type="submit" variant="contained" onSubmit={handleUpdateAd}>
            Salvar mudanças
          </Button>
        </form>
      )}
      <Snackbar
        open={success}
        onClose={handleClose}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="success" onClose={handleClose}>
          Propaganda criada com sucesso!
        </Alert>
      </Snackbar>
      <Snackbar
        open={error}
        onClose={handleClose}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error" onClose={handleClose}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </MainContainer>
  );
}
