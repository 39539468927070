import { ChoppbotEventFormField } from "./interfaces/events";

const availableFormFields = [
  ChoppbotEventFormField.face,
  ChoppbotEventFormField.name,
  ChoppbotEventFormField.company,
  ChoppbotEventFormField.phone,
  ChoppbotEventFormField.office,
  ChoppbotEventFormField.city,
  ChoppbotEventFormField.clientCode,
  ChoppbotEventFormField.email,
  ChoppbotEventFormField.identifier,
  ChoppbotEventFormField.profession,
  ChoppbotEventFormField.state,
];

export function backendFormFieldFromEnum(formField: ChoppbotEventFormField) {
  switch (formField) {
    case ChoppbotEventFormField.face:
      return "face";
    case ChoppbotEventFormField.name:
      return "name";
    case ChoppbotEventFormField.identifier:
      return "identifier";
    case ChoppbotEventFormField.phone:
      return "phone";
    case ChoppbotEventFormField.email:
      return "email";
    case ChoppbotEventFormField.company:
      return "company";
    case ChoppbotEventFormField.profession:
      return "profession";
    case ChoppbotEventFormField.office:
      return "office";
    case ChoppbotEventFormField.state:
      return "state";
    case ChoppbotEventFormField.city:
      return "city";
    case ChoppbotEventFormField.clientCode:
      return "client_code";
    default:
      throw new Error("Campo de formulário não definido");
  }
}

export const formFieldsModel: Record<
  ChoppbotEventFormField,
  { readonly required: boolean; readonly checked: boolean }
> = {
  [ChoppbotEventFormField.face]: {
    required: true,
    checked: true,
  },
  [ChoppbotEventFormField.name]: {
    required: true,
    checked: true,
  },
  [ChoppbotEventFormField.identifier]: {
    required: false,
    checked: false,
  },
  [ChoppbotEventFormField.phone]: {
    required: false,
    checked: false,
  },
  [ChoppbotEventFormField.email]: {
    required: false,
    checked: false,
  },
  [ChoppbotEventFormField.company]: {
    required: false,
    checked: false,
  },
  [ChoppbotEventFormField.profession]: {
    required: false,
    checked: false,
  },
  [ChoppbotEventFormField.office]: {
    required: false,
    checked: false,
  },
  [ChoppbotEventFormField.state]: {
    required: false,
    checked: false,
  },
  [ChoppbotEventFormField.city]: {
    required: false,
    checked: false,
  },
  [ChoppbotEventFormField.clientCode]: {
    required: false,
    checked: false,
  },
};
