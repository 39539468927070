import React from "react";
import useSWR from "swr";
import { getMachines } from "@/helpers/api/machines";
import { ChoppbotMachine } from "@/helpers/interfaces/machine";
import Cards from "@/components/Cards/Cards";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import MainContainer from "@/components/mainContainer/MainContainer";

export default function Machines() {
  const navigate = useNavigate();
  const { data, isLoading } = useSWR<ChoppbotMachine[]>(
    "/api/machine",
    getMachines,
  );

  if (isLoading) {
    return (
      <MainContainer>
        <h2>Minhas máquinas</h2>
        <div
          style={{
            display: "flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      </MainContainer>
    );
  }

  return (
    <MainContainer>
      <h2>Minhas máquinas</h2>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {data!.map((machine) => (
          <Cards
            key={machine.id}
            img="/assets/choppbotLogo.svg"
            name={machine.description}
            style={{ marginLeft: "0px" }}
            action={() => {
              navigate("/machines/" + machine.id);
            }}
          />
        ))}
      </div>
    </MainContainer>
  );
}
