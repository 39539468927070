export const downloadSVG = (
  svgElement: SVGSVGElement | null,
  filename?: string
) => {
  if (svgElement) {
    const svgData = new XMLSerializer().serializeToString(svgElement);
    const blob = new Blob([svgData], { type: "image/svg+xml" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = filename
      ? `qr-code_evento_${filename}.svg`
      : "qr-code_evento_indefinido.svg";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }
};
