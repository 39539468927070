import React, { useContext, useState } from "react";
import styles from './Login.module.css'
import { useRef } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import LoginAuth from "@/helpers/context/LoginContext";
import { Alert, Snackbar } from "@mui/material";

export default function NewPassword() {
  const formRef = useRef()
  let location = useLocation()
  const { user, baseURL } = useContext(LoginAuth)
  const URL_RESET_PASSWORD = baseURL + 'api/reset'
  const [openPasswordChange, setOpenPasswordChange] = useState()
  const [openPasswordError, setOpenPasswordError] = useState()
  let navigate = useNavigate()

  const handleSendForm = async (e) => {
    const params = location.pathname.split("/")
    e.preventDefault()
    if (formRef.current[0].value === formRef.current[1].value) {
      const response = await fetch(URL_RESET_PASSWORD, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': user,
        },
        body: JSON.stringify({
          "token": params[2],
          "email": params[3],
          "password": formRef.current[0].value,
          "password_confirmation": formRef.current[1].value
        })
      })

      if (response.ok) {
        setOpenPasswordChange(true)
        setTimeout(() => {
          navigate('/login')
        }, [5000])
      }
    } else {
      setOpenPasswordError(true)
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenPasswordChange(false)
    setOpenPasswordError(false)
  };

  return (
    <div className={styles.loginMain}>
      <Snackbar open={openPasswordChange} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} variant="filled" severity="success">
          Senha alterada com sucesso !
        </Alert>
      </Snackbar>
      <Snackbar open={openPasswordError} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} variant="filled" severity="error">
          Senha ou usuário invalido !
        </Alert>
      </Snackbar>
      <div className={styles.logBox}>
        <img src="/assets/logo.png" alt="Heimdall" />
        <h1>DIGITE SUA NOVA SENHA</h1>
        <form ref={formRef} id="forms">
          <input type="password" placeholder="Nova senha" id="password" name="password" />
          <input type="password" placeholder="Confirmar nova senha" id="confirm-password" name="confirm-password" />
          <button type="submit" onClick={handleSendForm}>TROCAR SENHA</button>
        </form>
      </div>
    </div>
  )
}