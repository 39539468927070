import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoginAuth from "@/helpers/context/LoginContext";
import api from "@/helpers/api/rootConfig";
import { Alert, Button, Snackbar, TextField } from "@mui/material";
import styles from "./Login.module.css";

export default function LostPassword() {
  const { user, baseURL } = useContext(LoginAuth);
  const URL_LOST_PASSWORD = baseURL + "api/forgot";
  const [openPasswordChange, setOpenPasswordChange] = useState();
  const [username, setUsername] = useState("");
  let navigate = useNavigate();

  const handleSendForm = async (e) => {
    e.preventDefault();
    const response = await fetch(URL_LOST_PASSWORD, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: user,
      },
      body: JSON.stringify({ email: username }),
    });

    if (response.ok) {
      setOpenPasswordChange(true);
      setTimeout(() => {
        navigate("/login");
      }, [5000]);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenPasswordChange(false);
  };

  return (
    <div className={`${styles.loginMain} primary-container`}>
      <Snackbar
        open={openPasswordChange}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} variant="filled" severity="success">
          E-mail com o link foi enviado com sucesso !
        </Alert>
      </Snackbar>
      <div className={`${styles.logBox} surface on-surface-text`}>
        <img src="/assets/logo.png" alt="Heimdall" />
        <h1>RECUPERAR</h1>
        <form id="forms">
          <div style={{ textAlign: "center" }}>
            Informe um usuário ou e-mail válido para receber um link e cadastrar
            uma nova senha
          </div>
          <TextField
            fullWidth
            id="username"
            value={username}
            variant="outlined"
            label="Usuário ou E-mail"
            style={{ marginBlock: "15px" }}
            onChange={(ev) => setUsername(ev.target.value)}
          />
          <Button
            fullWidth
            type="submit"
            variant="contained"
            onClick={handleSendForm}
          >
            OBTER NOVA SENHA
          </Button>
          <Link to="/auth/login">
            <Button
              fullWidth
              variant="text"
              style={{
                marginTop: "10px",
              }}
            >
              VOLTAR
            </Button>
          </Link>
        </form>
      </div>
    </div>
  );
}
