import React from "react";
import { Button } from "@mui/material";
import { AttachFile } from "@mui/icons-material";

export default function AttachCard({
  onChange,
  customIcon,
  color,
}: {
  onChange: (file: File) => void;
  customIcon?: React.ReactNode;
  color?:
    | "success"
    | "error"
    | "inherit"
    | "primary"
    | "secondary"
    | "info"
    | "warning"
    | undefined;
}) {
  return (
    <Button
      // className="w-36 h-32"
      style={{ width: "150px", height: "140px" }}
      variant="outlined"
      component="label"
      color={color}
    >
      {customIcon ?? (
        <AttachFile color={color ? color : "primary"} fontSize="large" />
      )}
      <input
        type="file"
        hidden
        onChange={(ev) => {
          if (ev.target.files && ev.target.files.length > 0)
            onChange(Array.from(ev.target.files)[0]);
        }}
      />
    </Button>
  );
}
