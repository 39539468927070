import React from "react";
import { Link } from "react-router-dom";
import EventIcon from "@mui/icons-material/Event";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SportsBarIcon from "@mui/icons-material/SportsBar";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import { PhotoLibrary } from "@mui/icons-material";
import { choppbotRoutes } from "@/routes";
import { useMediaQuery } from "@mui/material";
import styles from "./Sidebar.module.css";

export default function Sidebar() {
  return (
    <div className={`${styles.container} primary-container`}>
      <ul className={styles.links}>
        {choppbotRoutes[0].children?.map((route, index) =>
          route.index ? (
            <li key={index}>
              <NavItem path="/" />
            </li>
          ) : route.path !== "*" && route.path !== "/receipts/:id" ? (
            <li key={index}>
              <NavItem path={route.path} />
            </li>
          ) : null
        )}
      </ul>
    </div>
  );
}

function NavItem({ path }: { path?: string }) {
  const isMd = useMediaQuery("(max-width:1200px)");

  switch (path) {
    case "/":
      return (
        <Link to={path}>
          <DashboardIcon />
          {!isMd && <span>Dashboard</span>}
        </Link>
      );
    case "/ads":
      return (
        <Link to={path}>
          <PhotoLibrary />
          {!isMd && <span>Propagandas</span>}
        </Link>
      );
    case "/beers":
      return (
        <Link to={path}>
          <SportsBarIcon />
          {!isMd && <span>Cervejas</span>}
        </Link>
      );
    case "/machines":
      return (
        <Link to={path}>
          <PrecisionManufacturingIcon />
          {!isMd && <span>Minhas máquinas</span>}
        </Link>
      );
    case "/eventos":
      return (
        <Link to={path}>
          <EventIcon />
          {!isMd && <span> Eventos</span>}
        </Link>
      );

    case "/receipts":
      return (
        <Link to={path}>
          <ReceiptLongIcon />
          {!isMd && <span>Comandas</span>}
        </Link>
      );
    default:
      return null;
  }
}
