import "./FlashMessage.css";

function FlashMessage({ title, message, type }) {
  return (
    <div
      id="hideMe"
      className={
        type == "error"
          ? "error-container"
          : type == "info"
          ? "base-container"
          : type == "success"
          ? "success-container"
          : type == "warning"
          ? "warning-container"
          : null
      }
    >
      <img
        className="message-icon"
        src={
          type == "error"
            ? "/assets/error.svg"
            : type == "info"
            ? "/assets/info.svg"
            : type == "success"
            ? "/assets/check_circle.svg"
            : type == "warning"
            ? "/assets/warning_icon.svg"
            : null
        }
      />
      <div className="message-content">
        <h1>{title}</h1>
        <h3>{message}</h3>
      </div>
    </div>
  );
}

export default FlashMessage;
