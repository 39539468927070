import React, { useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import LoginAuth from "./helpers/context/LoginContext";

export default function AuthLayout() {
  const { user, setBaseURL, setUser } = useContext(LoginAuth);

  const navigate = useNavigate();

  function expireHandle() {
    setUser(null);
    navigate("/auth/login");
    // window.location.reload();
  }

  useEffect(() => {
    setBaseURL("https://www.choppbot.com.br");
    if (!user) {
      expireHandle();
    }
  }, []);

  return <Outlet />;
}
