import React, { useContext } from "react";
import LoginAuth from "@/helpers/context/LoginContext";
import { IconButton, MenuItem, Popover } from "@mui/material";

import styles from "./header.module.css";
import rootApi from "@/helpers/api/rootConfig";
import { AccountCircleOutlined, LogoutOutlined, PersonOutline } from "@mui/icons-material";

function Header() {
  const { user, setUser } = useContext(LoginAuth);

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  async function handleLogout() {
    const response = await rootApi
      .get("api/logout", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data)
      .catch((error) => console.error(error));

    if (response) {
      localStorage.clear();
      sessionStorage.clear();
      setUser(null);
    }
  }

  return (
    <div className={`${styles.container} primary-container`}>
      <img src="/assets/choppbotName.png" alt="choppbot" height="40px" />
      <IconButton aria-label="User menu" className={styles.actions} onClick={handleClick}>
        <PersonOutline
          sx={{ width: "30px", height: "30px", fill: "#333333" }}
        />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <ul style={{ width: "200px", paddingBlock: "8px" }}>
          <MenuItem>
            <AccountCircleOutlined sx={{ marginRight: "5px" }} />{" "}
            {user?.username}
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <LogoutOutlined sx={{ marginRight: "5px" }} /> Sair
          </MenuItem>
        </ul>
      </Popover>
    </div>
  );
}

export default Header;
