import React from "react";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import AdvertisementSet from "@/components/advertisementSet/AdvertisementSet";
import { Button, CircularProgress } from "@mui/material";
import { Add } from "@mui/icons-material";
import useSWR from "swr";
import { ChoppbotAd } from "@/helpers/interfaces/advertisements";
import { adsFetcher } from "@/helpers/api/advertisements";
import { useNavigate } from "react-router-dom";
import MainContainer from "@/components/mainContainer/MainContainer";

export default function Ads() {
  const navigate = useNavigate();
  const { data, isLoading } = useSWR<ChoppbotAd[]>(
    "/api/advertising",
    adsFetcher,
  );

  return (
    <MainContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "15px",
        }}
      >
        <h2>Minhas propagandas</h2>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<Add />}
          onClick={() => navigate("/ads/create-ad")}
        >
          Novo conjunto
        </Button>
      </div>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Grid2 container spacing={2}>
          {data?.map((ad, index) => (
            <AdvertisementSet
              key={index}
              ad={ad}
              // border="1px solid rgba(0,0,0,0.2)"
            />
          ))}
        </Grid2>
      )}
    </MainContainer>
  );
}
