import { ChoppbotEvent } from "./interfaces/events";

export function eventStatusTranslation(status?: ChoppbotEvent["status"]) {
  if (!status) return "---";
  switch (status) {
    case "created":
      return "Criado";
    case "activated":
      return "Em andamento";
    default:
      return "Encerrado";
  }
}
