import React from "react";
import styles from "./Cards.module.css";
import { Button } from "@mui/material";

interface CardsProps {
  name?: string;
  description?: string;
  img?: string;
  action?: () => void;
  style?: React.CSSProperties | undefined;
}

export default function Cards({
  name,
  description,
  img,
  action,
  style,
}: CardsProps) {
  return (
    <div className={styles.cards} style={style}>
      <img src={img} width="100%" alt="choppbotLogo" />
      <div style={{ padding: "12px", height: "128px", paddingBottom: "24px" }}>
        <h2 className={styles.title}>{name}</h2>
        <p className={styles.description}>{description}</p>
      </div>
      <div style={{ display: "flex", flex: 1, alignItems: "flex-end" }}>
        <Button variant="contained" onClick={action}>
          Detalhes
        </Button>
      </div>
    </div>
  );
}
