import { BackendResponsePagination } from "../interfaces/generic";
import { ChoppbotCustomer } from "../interfaces/customers";
import rootApi from "./rootConfig";

interface GetEventCustomersParams {
  eventId?: number | string;
  page?: number;
  pageSize?: number;
  nameFilter?: string;
  phoneFilter?: string;
  cpfFilter?: string;
  codeFilter?: string;
  statusFilter?: string;
}

export async function getEventCustomers(
  {
    eventId,
    page = 1,
    pageSize = 10000,
    nameFilter,
    phoneFilter,
    cpfFilter,
    codeFilter,
    statusFilter,
  }: GetEventCustomersParams = { page: 1, pageSize: 10000 }
) {
  let requestUrl = "api/customer?page=" + page;

  if (eventId) requestUrl += "&filter[event_id]=" + eventId;
  if (nameFilter) requestUrl += "&filter[name]=" + nameFilter;
  if (phoneFilter) requestUrl += "&filter[phone]=" + phoneFilter;
  if (cpfFilter) requestUrl += "&filter[identifier]=" + cpfFilter;
  if (codeFilter) requestUrl += "&filter[client_code]=" + codeFilter;
  if (statusFilter) requestUrl += "&filter[payment]=" + statusFilter;

  return await rootApi
    .get<BackendResponsePagination<ChoppbotCustomer>>(requestUrl, {
      headers: { paginate: pageSize },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => console.error(err));
}

export async function deleteAllCustomersFromEvent(eventId: number) {
  return await rootApi
    .delete(`api/customer/${eventId}/event`)
    .then((res) => res.data);
}

export async function changeCustomerRole(data: {
  customerId: number;
  event_id: number;
  franchisee_id?: number;
  permission: ChoppbotCustomer["permission"];
}) {
  const requestData: Omit<typeof data, "customerId"> = {
    event_id: data.event_id,
    permission: data.permission,
  };

  if (data.franchisee_id !== undefined) {
    requestData.franchisee_id = data.franchisee_id;
  }

  return await rootApi
    .put("api/customer/" + data.customerId, requestData)
    .then((res) => res.data);
}

export async function deleteCustomer(customerId: number) {
  return await rootApi
    .delete("api/customer/" + customerId)
    .then((res) => res.data);
}
