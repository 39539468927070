import styles from "./BeerCards.module.css";
import { useState, useEffect } from "react";
import Cards from "@/components/Cards/Cards";
import MainContainer from "@/components/mainContainer/MainContainer";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { getEvents } from "@/helpers/api/events";

export default function Beers() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);

  const fetchEvents = async () => {
    setIsLoading(true);

    await getEvents()
      .then((events) => {
        const transformData = events.data.map((event) => {
          return {
            id: event.id,
            franchisee: event.franchisee.id,
            event: event.name,
            description: event.description,
            qrcode: event.link,
            image: event.collection,
            category:
              event.category === "prepaid"
                ? "Pré-pago"
                : event.category === "postpaid"
                ? "Pós-pago"
                : "Open",
            created_at: event.started_at,
            status:
              event.status === "created"
                ? "Criado"
                : event.status === "activated"
                ? "Em andamento"
                : "Finalizado",
            action: event.id,
          };
        });
        setData(transformData);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  return (
    <MainContainer>
      <div className={styles.headerContainer}>
        <h1 style={{ fontSize: "24px", fontWeight: 400 }}>
          Cervejas por evento
        </h1>
      </div>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div className={styles.cardsList}>
          {data.map((card, index) => (
            <Cards
              key={index}
              name={card.event}
              img="/assets/choppbotLogo.svg"
              description={card.description}
              action={() => navigate(`/beers/event/${card.id}`)}
            />
          ))}
        </div>
      )}
    </MainContainer>
  );
}
