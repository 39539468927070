import { Route, createRoutesFromElements } from "react-router-dom";
import MainLayout from "./MainLayout";
import Private from "./components/Private/Private";
import Dashboard from "./pages/dashboard/Dashboard";
import Beers from "./pages/beers/Beers";
import Home from "./pages/events/Eventos";
import Machines from "./pages/machines/Machines";
import Machine from "./pages/machines/[id]/Machine";
import Ads from "./pages/ads/Ads";
import NotFound from "./pages/NotFound";
import AuthLayout from "./AuthLayout";
import NewPassword from "./pages/auth/NewPassword";
import Login from "./pages/auth/Login";
import LostPassword from "./pages/auth/LostPassword";
import CreateAd from "./pages/ads/create-ad/CreateAd";
import EditAd from "./pages/ads/edit-ad/EditAd";
import Receipt from "./pages/receipts/[id]/Receipt";
import EventBeers from "./pages/beers/event/[id]/event-beers";

export const choppbotRoutes = createRoutesFromElements([
  <Route path="/" element={<MainLayout />}>
    <Route
      index
      element={
        <Private>
          <Dashboard />
        </Private>
      }
    />
    <Route
      path="/eventos"
      element={
        <Private>
          <Home />
        </Private>
      }
    />
    <Route path="/beers">
      <Route
        index
        element={
          <Private>
            <Beers />
          </Private>
        }
      />
      <Route
        path="/beers/event/:eventId"
        element={
          <Private>
            <EventBeers />
          </Private>
        }
      />
    </Route>
    <Route path="/machines">
      <Route
        index
        element={
          <Private>
            <Machines />
          </Private>
        }
      />
      <Route
        path="/machines/:machineId"
        element={
          <Private>
            <Machine />
          </Private>
        }
      />
    </Route>
    <Route path="/ads">
      <Route
        index
        element={
          <Private>
            <Ads />
          </Private>
        }
      />
      <Route
        path="/ads/create-ad"
        element={
          <Private>
            <CreateAd />
          </Private>
        }
      />
      <Route
        path="/ads/edit-ad/:id"
        element={
          <Private>
            <EditAd />
          </Private>
        }
      />
    </Route>
    <Route
      path="/receipts/:id"
      element={
        <Private>
          <Receipt />
        </Private>
      }
    />
    {/* <Route path="/receipts">
      <Route
        index
        element={
          <Private>
            <Comandas />
          </Private>
        }
      />
      <Route
        path="/receipts/:id"
        element={
          <Private>
            <Receipt />
          </Private>
        }
      />
    </Route> */}
    <Route path="*" element={<NotFound />} />
  </Route>,
  <Route path="/auth" element={<AuthLayout />}>
    <Route path="/auth/reset" element={<NewPassword />} />
    <Route path="/auth/login" element={<Login />} />
    <Route path="/auth/lostpassword" element={<LostPassword />} />
  </Route>,
]);
