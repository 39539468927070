import { Fragment, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";
import LoginAuth from "../helpers/context/LoginContext";

export default function NotFound() {
  const paths = [
    "/",
    "/dashboards",
    "/logAgglomeration",
    "/logIntrusion",
    "/logEvasion",
    "/logRadar",
    "/logCameras",
    "/log",
    "/suporte",
  ];

  function pathnameFind(path: string, array: string[]) {
    var flag = false;
    for (var pathname in array) {
      if (path === array[pathname]) {
        return (flag = true);
      }
    }
    return flag;
  }

  const { user } = useContext(LoginAuth);
  const location = useLocation();
  var PATH = "";

  useEffect(() => {
    if (pathnameFind(location.pathname, paths)) {
      PATH = location.pathname;
    } else {
      PATH = "/";
    }
  }, [location]);

  return (
    <Fragment>
      {user ? <Navigate to={PATH} replace /> : <Navigate to="/login" />}
    </Fragment>
  );
}
