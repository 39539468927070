import styles from "./AddBeer.module.css";
import LoginAuth from "../../helpers/context/LoginContext";
import { useCallback, useContext } from "react";
import { useState } from "react";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";

interface AddEventBeerProps {
  open: boolean;
  eventID: number | string;
  title: string;
  onClose: () => void;
  onSubmit: () => void;
}

function AddEventBeer({
  open,
  title,
  eventID,
  onClose,
  onSubmit,
}: AddEventBeerProps) {
  const { user, baseURL } = useContext(LoginAuth);
  const [nameOneBeer, setNameOneBeer] = useState("");
  const [nameTwoBeer, setNameTwoBeer] = useState("");
  const [sizeOneBeer, setSizeOneBeer] = useState("");
  const [sizeTwoBeer, setSizeTwoBeer] = useState("");
  const [errorBeerName1, setErrorBeerName1] = useState(false);
  const [errorBeerName2, setErrorBeerName2] = useState(false);
  const [errorBeerPrice1, setErrorBeerPrice1] = useState(false);
  const [errorBeerPrice2, setErrorBeerPrice2] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [submitErrorMessage, setSubmitErrorMessage] = useState(
    "Um erro inesperado ocorreu"
  );

  /**
   * Sets fields errors and tells if there is no error
   * @returns `true` if there is at least one field error. `false` otherwise
   */
  const handleFieldErrors = useCallback((): boolean => {
    let hasError = false;
    if (!nameOneBeer) {
      setErrorBeerName1(true);
      hasError = true;
    } else {
      setErrorBeerName1(false);
    }
    if (!sizeOneBeer) {
      setErrorBeerPrice1(true);
      hasError = true;
    } else {
      setErrorBeerPrice1(false);
    }
    if (!nameTwoBeer) {
      setErrorBeerName2(true);
      hasError = true;
    } else {
      setErrorBeerName2(false);
    }
    if (!sizeTwoBeer) {
      setErrorBeerPrice2(true);
      hasError = true;
    } else {
      setErrorBeerPrice2(false);
    }
    return hasError;
  }, [nameOneBeer, nameTwoBeer, sizeOneBeer, sizeTwoBeer]);

  const AddNewBeers = async () => {
    const hasFieldErrors = handleFieldErrors();
    if (hasFieldErrors) return;

    const options1 = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      },
      body: JSON.stringify({
        franchisee_id: 1,
        event_id: eventID,
        beer_name: nameOneBeer,
        price_liters: sizeOneBeer,
        price_unit: sizeOneBeer,
      }),
    };

    const options2 = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      },
      body: JSON.stringify({
        franchisee_id: 1,
        event_id: eventID,
        beer_name: nameTwoBeer,
        price_liters: sizeTwoBeer,
        price_unit: sizeTwoBeer,
      }),
    };

    await fetch(baseURL + "/api/beer", options1)
      .then(async (response) => {
        if (response.status >= 400) {
          throw await response.json();
        }
        await fetch(baseURL + "/api/beer", options2).then(async (response) => {
          if (response.status >= 400) {
            throw await response.json();
          }
          response.json();
          onSubmit();
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.errors) {
          setSubmitErrorMessage(err.errors);
        }
        setSubmitError(true);
      });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Criação de bebidas - Evento {title}</DialogTitle>
      <DialogContent>
        <div className={styles.inputsContainer}>
          <TextField
            margin="dense"
            error={errorBeerName1}
            label="Nome da primeira cerveja"
            helperText={errorBeerName1 && "Campo obrigatório"}
            onChange={(ev) => setNameOneBeer(ev.target.value)}
          />
          <TextField
            margin="dense"
            placeholder="00.00"
            label="Preço por litro"
            error={errorBeerPrice1}
            helperText={errorBeerPrice1 && "Campo obrigatório"}
            InputProps={{
              startAdornment: <span style={{ marginRight: "4px" }}>R$</span>,
            }}
            onChange={(value) => setSizeOneBeer(value.target.value)}
          />
          <TextField
            margin="dense"
            error={errorBeerName2}
            label="Nome da segunda cerveja"
            helperText={errorBeerName2 && "Campo obrigatório"}
            onChange={(value) => setNameTwoBeer(value.target.value)}
          />
          <TextField
            margin="dense"
            placeholder="00.00"
            label="Preço por litro"
            error={errorBeerPrice2}
            helperText={errorBeerPrice2 && "Campo obrigatório"}
            InputProps={{
              startAdornment: <span style={{ marginRight: "4px" }}>R$</span>,
            }}
            onChange={(value) => setSizeTwoBeer(value.target.value)}
          />
        </div>
        {submitError && (
          <Alert sx={{ marginTop: "8px" }} severity="error">
            {submitErrorMessage}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={onClose}>
          Cancelar
        </Button>
        <Button onClick={AddNewBeers}>Salvar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddEventBeer;
