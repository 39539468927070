import styles from "./Login.module.css";
import { useEffect, useState } from "react";
import LoginAuth from "@/helpers/context/LoginContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Button,
  Checkbox,
  FormControlLabel,
  Snackbar,
  TextField,
} from "@mui/material";
import rootApi from "@/helpers/api/rootConfig";

export default function Login() {
  const { user, setUser } = useContext(LoginAuth);
  const [openPasswordError, setOpenPasswordError] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  let navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user]);

  function clearFields() {
    // setUsername("");
    setPassword("");
  }

  const handleClose = (
    event: Event | React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenPasswordError(false);
  };

  const signIn = async () => {
    try {
      const response = await rootApi.post("/api/login", {
        email: username,
        password: password,
      });

      return response.data;
    } catch (e: any) {
      if (e.response) {
        if (e.response.status === 401) {
          setOpenPasswordError(true);
        } else if (e.response.status === 406) {
          console.error("Senha deve ter no minimo 4 caracteres");
        }
      }
    }
  };

  async function handleSignIn(e: React.FormEvent) {
    e.preventDefault();

    const userData = await signIn();

    if (!userData) {
      clearFields();
    } else {
      localStorage.setItem("token", userData.token);
      rootApi.defaults.headers["Authorization"] = "Bearer " + userData.token;
      setUser(userData);
    }
  }

  return (
    <div className={`${styles.loginMain} primary-container`}>
      <div className={`${styles.logBox} surface on-surface-text`}>
        <img src="/assets/logo.png" alt="Heimdall" />
        <h1>LOGIN</h1>
        <form id="forms">
          <TextField
            fullWidth
            variant="outlined"
            label="Usuário ou E-mail"
            value={username}
            id="email"
            onChange={(ev) => setUsername(ev.target.value)}
          />
          <TextField
            fullWidth
            variant="outlined"
            type="password"
            label="Senha"
            value={password}
            onChange={(ev) => setPassword(ev.target.value)}
            id="password"
            style={{ marginBlock: "15px" }}
          />
          <FormControlLabel
            control={<Checkbox id="lembre" name="lembre" />}
            label="Lembrar"
            style={{ marginBottom: "15px" }}
          />
          <Button
            fullWidth
            type="submit"
            onClick={handleSignIn}
            variant="contained"
            // style={{
            //   color: "var(--md-sys-color-on-primary)",
            // }}
          >
            Acessar
          </Button>
          <Button
            fullWidth
            style={{
              marginTop: "10px",
            }}
            onClick={() => navigate("/auth/lostpassword")}
          >
            Esqueceu sua senha ?
          </Button>
        </form>
      </div>
      <Snackbar
        open={openPasswordError}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} variant="filled" severity="error">
          Credenciais inválidas
        </Alert>
      </Snackbar>
    </div>
  );
}
