import rootApi from "./rootConfig";

export async function adsFetcher(path: string) {
  const response = await rootApi.get(path);

  return response.data.data;
}

export async function postAd(data: FormData) {
  return await rootApi.post("/api/advertising", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function putAd(id: number, data: FormData) {
  return await rootApi.post(`/api/advertising/${id}?_method=PUT`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function deleteAd(id: number) {
  return await rootApi.delete("/api/advertising/" + id);
}
