import { createTheme } from "@mui/material";

const cssVar = (name: string) =>
  getComputedStyle(document.documentElement).getPropertyValue(name).trim();

export const theme = createTheme({
  palette: {
    primary: {
      main: cssVar("--md-sys-color-primary"),
      light: cssVar("--md-sys-color-primary"),
      dark: cssVar("--md-sys-color-primary"),
      contrastText: "white",
    },
    secondary: {
      main: cssVar("--md-sys-color-secondary"),
      light: cssVar("--md-sys-color-secondary"),
      dark: cssVar("--md-sys-color-secondary"),
      contrastText: "white",
    },
    background: {
      default: cssVar("--md-sys-color-surface"),
    },
    text: {
      primary: cssVar("--md-sys-color-on-primary-container"),
      disabled: "#666",
    },
    divider: "#000",
  },
  shape: {
    borderRadius: 12,
  },
  components: {
    // Name of the component
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          textTransform: "unset",
        },
      },
    },
  },
});
